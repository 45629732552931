export default {
  data() {
    return {
      messageList: [],
      pageNo: 1,
      pageSize: 10000
    };
  },
  mounted() {
    this.getMsgType();
  },
  methods: {
    // 获取分类信息
    getMsgType() {
      let data = {
        page: this.pageNo,
        pageSize: this.pageSize,
        msgtype: 3
      };
      this.$http.getMsgList(data).then(res => {
        if (res.code == 1) {
          this.messageList = res.data.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    backClick() {
      this.$router.back();
    }
  }
};